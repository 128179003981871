<template>
  <div class="login">
    <div style="text-align: center; width: 290px">
      <el-card style="height: 200px; padding-top: 20px">
        <el-input v-model="loginInfo.account" placeholder="账号">
          <template #prepend>
            <el-button :icon="User" />
          </template>
        </el-input>
        <el-input
          v-model="loginInfo.password"
          style="margin: 20px 0"
          placeholder="密码"
          type="password"
        >
          <template #prepend>
            <el-button :icon="Lock" />
          </template>
        </el-input>
        <el-button v-debounce type="primary" style="width: 100%" @click="login"
          >登录</el-button
        >
      </el-card>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { imitate_login } from "@/api/public/index.js";
import { useCache } from "@/hooks/useCache.js";
import { useMenu } from "@/store/menu.js";
import { useRouter } from "vue-router";
import { getAuthMore, initReturnArr } from "@/utils/index.js";
import { User, Lock } from "@element-plus/icons-vue";
import md5 from "js-md5";

const { wsCache } = useCache();
const router = useRouter();

const loginInfo = reactive({
  account: "",
  password: "",
});

// 登录
const login = () => {
  imitate_login({
    account: loginInfo.account,
    password: md5(md5(loginInfo.password)),
  }).then((data) => {
    if (data.code == 200) {
      // 逐个测试权限
      // let dataTest = {"auth":{"user":["list_user","add_user","update_user","initialize_password","ban_user"],"character":["add_character","update_auth","update_character","delete_character"],"log":["list_login","list_message","list_operate"]},"custom":{"remittance":["info_verification","examine_verification","copy_elist_no","info_declaration","download_elist"]},"ent":{"enterprise":["get_enterprise","change_manager","black_list_status","add_tag","update_enterprise_type","update_declare_type"]}}
      // data.data.permission = dataTest
      // 避免权限空时数组检验报错
      if (Object.keys(data.data.permission).length === 0) {
        data.data.permission["OccupationPosition"] = [];
      }
      const authAll = getAuthMore(data.data.permission);
      initReturnArr();
      wsCache.set("Auth", authAll);
      wsCache.set("Token", data.data.token);
      const menu = useMenu();
      const waitInit = menu.setImportMenu(data.data.permission);
      if (waitInit) {
        router.replace("/import");
      }
    }
  });
};
</script>

<style scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login_box {
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
